<template>
  <el-main>
    <el-row class="movie-list">
      <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的钱包</span>
            <el-button style="float: right; padding: 3px; color: red" type="text" @click="showTransferDialog">转帐</el-button>
            <el-button style="float: right; padding: 3px; color: green" type="text" @click="showChargeDialog">充值</el-button>
          </div>
          <div class="text item">
            <el-row>
              <h1>余额: <span style="color: green">￥{{ wallet.balance }} 元</span></h1>
            </el-row>
          </div>
        </el-card>
      </el-col>
      <el-col :md="16" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的账单</span>
          </div>
          <el-table
            :data="dataList"
            style="width: 100%"
          >
            <el-table-column
              prop="createAt"
              label="时间"
            />
            <el-table-column
              prop="type"
              label="类型"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.type === '支出'" style="color: red">
                  {{ scope.row.type }}
                </span>
                <span v-else style="color: green">
                  {{ scope.row.type }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="金额"
            />
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleDetail(scope.$index, scope.row)"
                >详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :small="screenWidth <= 768"
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="currentPage"
            :total="totalSize"
            @current-change="handleCurrentChange"
            @prev-click="handleCurrentChange"
            @next-click="handleCurrentChange"
          />
        </el-card>
      </el-col>
    </el-row>

    <el-dialog
      title="充值"
      append-to-body
      :visible.sync="chargeDialog"
      width="30%"
      center
    >
      <el-form ref="form" :model="chargeForm">
        <el-form-item label="充值金额">
          <el-input-number v-model="chargeForm.quantity" :min="1" :max="100" style="margin-left: 5px" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="chargeWallet"
          >确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="转账"
      append-to-body
      :visible.sync="transferDialog"
      width="30%"
      center
    >
      <el-form ref="form" :model="transferForm">
        <el-form-item label="转账给">
          <el-select v-model="transferForm.userId" placeholder="请选择联系人">
            <el-option
              v-for="item in contactList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="转帐金额">
          <el-input-number v-model="transferForm.quantity" :min="1" :max="10000" style="margin-left: 5px" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="transferTo"
          >确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-main>
</template>

<script>
import { chargeWallet, getWallet, getWalletBill } from '@/api/mall'

export default {
  name: 'MyWallet',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 20,
      totalSize: 0,
      dataList: [],
      chargeDialog: false,
      chargeForm: {
        quantity: null
      },
      contactList: [],
      transferDialog: false,
      transferForm: {
        userId: 0,
        quantity: null
      },
      wallet: {
        balance: '0.00'
      }
    }
  },
  created() {
    document.title = '我的钱包'
    getWallet().then(resp => {
      if (resp.code === 0) {
        this.wallet = resp.data
      }
    })

    this.getBillRecord()
  },
  methods: {
    handleCurrentChange(pageNumber) {
    },
    showChargeDialog() {
      this.chargeDialog = true
    },
    chargeWallet() {
      if (this.chargeForm.quantity === null) {
        this.$message('请填写充值金额')
        return
      }

      this.chargeDialog = false
      chargeWallet(this.chargeForm).then(resp => {
        if (resp.code === 0) {
          this.$notify.info({
            title: '充值请求已提交',
            duration: 3000
          })
        } else {
          this.$notify({
            title: '充值失败',
            message: resp.msg,
            type: 'error',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '充值失败',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    showTransferDialog() {
      this.transferDialog = true
      this.$message.info('获取联系人')
    },
    transferTo() {
      this.$message.info('转帐')
    },
    getBillRecord() {
      getWalletBill().then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data
        }
      })
    },
    handleDetail(index, row) {
      this.$message.info('账单详情')
    }
  }
}
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
